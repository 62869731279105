import { createApp } from './app'
import { triggerAbtRouter } from '@shein-aidc/basis-abt-router'
import { businessLibInitializeForCSR } from 'public/src/pages/product_app/business-lib-initialize/csr.js'

triggerAbtRouter({ routeName: 'checkout' })
const { app, store } = createApp()

if (window.gbCheckoutSsrData) {
  businessLibInitializeForCSR({ router: null })
  Object.assign( store.state.checkout, { 
    checkout: window.gbCheckoutSsrData.checkout, 
    checkoutAbtInfo: window.gbCheckoutSsrData.checkout.abtInfo || {}, 
    language: window.gbCheckoutSsrData.language, 
    ssrPageType: window.gbCheckoutSsrData.ssrPageType, 
    locals: window.gbCheckoutSsrData.locals, 
    isSSR: true,
  }) 
  if (window.gbCheckoutSsrData.checkout.nowDefaultTokenByBin) {
    Object.assign(store.state.checkout, {
      defaultSelectTokenPay: window.gbCheckoutSsrData.checkout.nowDefaultTokenByBin || {},
      handlerSelectBin: window.gbCheckoutSsrData.checkout.nowDefaultTokenByBin?.card_bin || '',
    })
  }

  Object.assign(store.state.checkout, {
    installmentSelectBin: window.gbCheckoutSsrData.checkout.defaultTokenByMallCal?.card_bin || '', // 计价接口返回的最优 token bin
    installmentSelectToken: window.gbCheckoutSsrData.checkout.defaultTokenByMallCal || {}, // 计价接口返回的最优 token bin
  })
}
app.$mount('#checkout-app')
